import { Component, OnInit, Input } from "@angular/core";
import {
  Validators,
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
} from "@angular/forms";
import {
  IPurchasesInvoices,
  IPurchaseInvoice,
} from "../../../../../@interfaces/purchaseInvoice.interface";
import { IArticles } from "../../../../../@interfaces/article.interface";
import { IProvider } from "../../../../../@interfaces/provider.interface";
import { Subject, Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog } from "@angular/material/dialog";
import { PurchasesService } from "../../../../../@pages/purchases/purchases.service";
import { ProviderService } from "../../../../../@pages/provider/provider.service";
import { takeUntil, startWith } from "rxjs/operators";
import { IPurchaseQuery } from "../../../../../@interfaces/purchases.interface";
import { DialogComponent } from "../../dialog.component";
import { ModifyPurchaseService } from "./modify-purchase.service";
import { RegisterPurchaseService } from "../register-purchase/register-purchase.service";
import { ICities } from "../../../../../@interfaces/cities.interface";
import { map } from "rxjs/internal/operators/map";
import { TributaryService } from "../../../../../@pages/tributary/tributary.service";
import { ConverseFilesService } from "../../../services/files/converse-files.service";
import { IWarehouse } from "src/app/@interfaces/warehouse.interface";
import { WarehouseService } from "src/app/@pages/warehouse/warehouse.service";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { IStorageCompany } from "src/app/@interfaces/company.interface";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
import { TaxValidationsService } from "../../../services/validations/tax-validations.service";
import { AlertsService } from "../../../services/alerts/alerts.service";

@Component({
  selector: "app-modify-purchase",
  templateUrl: "./modify-purchase.component.html",
  styleUrls: ["./modify-purchase.component.css"],
})
export class ModifyPurchaseComponent implements OnInit {
  @Input() id_invoice: number = 0;
  uvtValue = 0;
  totalPurchase: number = 0;
  totalWithoutTax: number = 0;
  totalTax: number = 0;
  withholdingTax: number = 0;
  icaTax: number = 0;
  customerCity = "";
  providerCity = "";
  newArticle: UntypedFormControl = this.fb.control("", Validators.required);
  newProvider: UntypedFormControl = this.fb.control("", Validators.required);
  purchaseForm: UntypedFormGroup = this.fb.group({
    itemRows: this.fb.array([
      this.fb.group({
        ids: [],
        warehouse: ["", Validators.required],
        article: [, Validators.required],
        quantity: [, Validators.required],
        price: [, Validators.required],
        discount: [0, Validators.required],
        subtotal: [0],
      }),
    ]),
    written_invoice: ["", Validators.required],
    tax_include: false,
    clause: false,
    tax: [, Validators.required],
    date: [, Validators.required],
    dueDate: [, Validators.required],
  });
  get rowsArr() {
    return this.purchaseForm.get("itemRows") as UntypedFormArray;
  }
  purcahsesIds: number[] = [];
  purchasesIdsDelete: number[] = [];
  purchaseToModify = {} as IPurchaseInvoice;
  dateToday = new Date();
  setPurchaseInvoice = {} as IPurchasesInvoices;
  purchases: Array<IPurchaseQuery> = [];
  articleList: Array<IArticles> = [];
  providersList: Array<IProvider> = [];
  warehouseList: IWarehouse[] = [];
  companyId = '';
  user = '';
  rol = '';
  unsubscribe$ = new Subject();
  citiesList: ICities[] = [];
  citiesProviderForm: UntypedFormControl = this.fb.control("", Validators.required);
  filteredOptionsProviderCities: Observable<ICities[]> | undefined;
  citiesCustomerForm: UntypedFormControl = this.fb.control("", Validators.required);
  filteredOptionsCustomerCities: Observable<ICities[]> | undefined;
  companyObject = {} as IStorageCompany;;

  constructor(
    private spinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    public purchasesService: PurchasesService,
    private fb: UntypedFormBuilder,
    private providersService: ProviderService,
    private modifyPurchaseService: ModifyPurchaseService,
    private registerPurchaseService: RegisterPurchaseService,
    private tributaryService: TributaryService,
    private converseFilesService: ConverseFilesService,
    private taxValidationsService: TaxValidationsService,
    private warehouse: WarehouseService,
    private alertsService: AlertsService,
    private cookieAuthService: CookieAuthService
  ) {}

  async ngOnInit() {
    this.spinner();
    this.getAuthValues();
    this.citiesList = await this.getCities();
    this.purchaseToModify = await this.getPurchaseInvoice();
    this.setPurchase(this.purchaseToModify);
    this.getInputValues();
    this.filteredArrays();
    this.data();
  }

  getAuthValues() {
    this.companyObject = this.cookieAuthService.getCompanyObject!;
    this.companyId = this.companyObject.Id_company!.toString();
    this.user = this.cookieAuthService.getUserId!;
    this.rol = this.cookieAuthService.getRolId!;
  }

  data() {
    this.warehouse
    .listWarehouses()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      this.warehouseList = result;
    });
  }

  async setPurchase(purchaseToModify: IPurchaseInvoice) {
    const { provider } = purchaseToModify;
    const { withholdingTax } = purchaseToModify;
    const { icaTax } = purchaseToModify;
    const { customerCity } = purchaseToModify;
    const { providerCity } = purchaseToModify;
    const { purchases } = purchaseToModify;
    this.withholdingTax = withholdingTax!;
    this.icaTax = icaTax!;
    this.setCitiesForm(customerCity!, providerCity!, this.citiesList);
    this.purchases = purchases;
    const [parameter] = this.purchases;
    const { total } = parameter;
    this.totalPurchase = total;
    this.editPurchase(this.purchaseToModify, this.purchases, provider);
  }

  getPurchaseInvoice() {
    return new Promise<any>((resolve, reject) => {
      this.purchasesService
      .getPurchaseInvoice(this.id_invoice)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result);
      });
    });
  }

  getCities() {
    return new Promise<any>((resolve, reject) => {
      const country_id = this.companyObject.countries[0].id_country;
      this.tributaryService
      .getCitiesByCompany(parseInt(country_id!.toString()))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        resolve(result);
      });
    });
  }

  filteredArrays() {
    this.filteredOptionsCustomerCities =
    this.citiesCustomerForm.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterC(value))
    );
    this.filteredOptionsProviderCities =
    this.citiesProviderForm.valueChanges.pipe(
      startWith(""),
      map((value) => this._filterC(value))
    );
  }

  setCitiesForm(customerCity: string, providerCity: string, citiesList: ICities[]) {
    if (customerCity) {
      const setCustomerCity = citiesList.filter((city) =>
        this.converseFilesService.removeAccents(
          city.name.toString().toLocaleLowerCase()
        ) ===
        this.converseFilesService.removeAccents(
          customerCity.toString().toLocaleLowerCase().trim()
        )
      );
      if (setCustomerCity.length > 0) {
        this.citiesCustomerForm.setValue(setCustomerCity[0]);
      }
    }
    if (providerCity) {
      const setProviderCity = citiesList.filter((city) =>
        this.converseFilesService.removeAccents(
          city.name.toString().toLocaleLowerCase()
        ) ===
        this.converseFilesService.removeAccents(
          providerCity.toString().toLocaleLowerCase().trim()
        )
      );
      if (setProviderCity.length > 0) {
        this.citiesProviderForm.setValue(setProviderCity[0]);
      }
    }
    this.customerCity = customerCity!;
    this.providerCity = providerCity!;
  }

  getInputValues() {
    this.newProvider.valueChanges
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((value: any) => {
      if (!value.__typename) {
        this.providersService
        .searchProvider(value, null, null)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result: any) => {
          this.providersList = result.providers;
        });
      }
    });
  }

  onProviderSelected(event: MatAutocompleteSelectedEvent) {
    if (event.option.value) {
      this.calculation();
    }
  }

  addNewRow() {
    if (this.purchaseForm.invalid) {
      return;
    }
    const newRow = this.fb.group({
      warehouse: ['', Validators.required],
      article: ['', Validators.required],
      quantity: [0, Validators.required],
      price: [0, Validators.required],
      discount: [0, Validators.required],
      subtotal: [0, Validators.required],
    });
    this.rowsArr.push(newRow);
    this.calculation();
  }

  deleteRow(rowIndex: number, id_purchaseD: number) {
    if (this.rowsArr.length > 1) {
      this.rowsArr.removeAt(rowIndex);
      this.purchasesIdsDelete.push(parseInt(id_purchaseD.toString()));
    } else if (this.rowsArr.length === 1) {
      this.alertsService.showWarningAlert("You should not remove the last row")
    }
    this.calculation();
  }

  async editPurchase(purchasesInvoices: any, purchases: IPurchaseQuery[], provider: IProvider[]) {
    this.purchaseForm.patchValue({
      written_invoice: purchasesInvoices.contpurchase[0].written_invoice,
      tax: purchasesInvoices.tax,
      date: purchasesInvoices.createdAt,
      dueDate: purchasesInvoices.dueDate,
      tax_include: this.getTaxIncluded(purchasesInvoices),
      clause: this.getClause(purchasesInvoices)
    });
    this.providersService
    .getProvider(provider[0].id_provider!)
    .subscribe((providerRes) => {
      this.newProvider.setValue(providerRes);
    });
    this.purchaseForm.setControl(
      "itemRows",
      await this.setPurchases(purchases)
    );
  }

  getClause(purchasesInvoices: any) {
    return purchasesInvoices.clause  === "true" ? true : false;
  }

  getTaxIncluded(purchasesInvoices: any) {
    return purchasesInvoices.tax_incl === "true" ? true : false;
  }

  async setPurchases(purchasesSets: IPurchaseQuery[]): Promise<UntypedFormArray> {
    const formArray = new UntypedFormArray([]);
    for (let index = 0; index < purchasesSets.length; index++) {
      formArray.push(
        this.fb.group({
          ids: purchasesSets[index].id_purchases,
          warehouse: purchasesSets[index].warehouse,
          article: purchasesSets[index].articulo,
          quantity: purchasesSets[index].quantity,
          discount: purchasesSets[index].discount,
          price: purchasesSets[index].price,
          subtotal: purchasesSets[index].subtotal,
        })
      );
    }
    const baseTax = await this.registerPurchaseService.getTaxBase();
    this.uvtValue = baseTax[0].value;
    return formArray;
  }

  validRegister() {
    if (this.purchaseForm.invalid === true) {
      this.alertsService.showErrorAlert("Please, fill in all the required fields!")
      this.spinnerService.hide();
      return false;
    } else if (this.rowsArr.length > 12) {
      this.alertsService.showErrorAlert(
        "The purchase cannot have more than 12 items, please delete",
        " " + (this.rowsArr.length - 12)
      )
      this.spinnerService.hide();
      return false;
    }else {
      return true
    }
  }

  validDeletePurchases() {
    if (this.rowsArr.value.length === this.purchases.length) {
      for (let i = 0; i < this.purchasesIdsDelete.length; i++) {
        this.purchasesIdsDelete.pop();
      }
    }
  }

  update() {
    const validations = this.validRegister();
    if(!validations) {
      return;
    }else {
      this.spinnerService.show();
      this.calculation();
      if ((this.citiesProviderForm.invalid || this.citiesCustomerForm.invalid) && (this.companyObject.external_electronic_invoicing === "true" || this.companyObject.internal_electronic_invoicing === "true")) {
        this.alertsService.showErrorAlert("This city does not exist")
        this.spinnerService.hide();
        return;
      } else {
        const variables = this.modifyPurchaseService.setQuotationData(
          this.purchaseForm,
          this.rowsArr,
          this.newProvider
        );
        this.validDeletePurchases();
        this.purchasesService
        .updatePurchase(
          variables.contpurchase![0].written_invoice,
          this.id_invoice,
          variables.tax,
          this.withholdingTax,
          this.icaTax,
          this.customerCity,
          this.providerCity,
          variables.provider[0].id_provider!,
          variables.purchases[0].id_purchases,
          this.purchasesIdsDelete,
          variables.purchases[0].id_warehouse,
          variables.purchases[0].codigo,
          variables.purchases[0].quantity,
          variables.purchases[0].price,
          variables.purchases[0].discount,
          variables.purchases[0].subtotal,
          variables.purchases[0].total,
          variables.createdAt,
          variables.dueDate,
          variables.tax_incl!,
          variables.clause,
          parseInt(this.user!)
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (result) {
            this.spinnerService.hide();
            this.alertsService.showSuccessfullAlert("Purchase has beed updated successfully")
            .then(async (result) => {
              this.purchaseToModify = await this.getPurchaseInvoice();
              this.setPurchase(this.purchaseToModify);
              if (result.isConfirmed) {
                this.dialog.closeAll();
                this.alertsService.refresh("/card", "/purchases")
              }
            });
          } else {
            this.spinnerService.hide();
            this.alertsService.showErrorAlert("Something was wrong")
          }
        },(error) => {
          this.spinnerService.hide();
        });
      }
    }
  }

  async calculation() {
    let priceCal = 0;
    let quantityCal = 0;
    let discountCal = 0;
    let taxCal = 0;
    let subsubtotal = 0;
    let totalWithoutTax = 0;
    let totalTax = 0;
    const contributorID = parseInt(this.newProvider.value.contributorType.id_contributor);

    for (let i = 0; i < this.rowsArr.length; i++) {
      if (this.purchaseForm.get("tax_include")?.value === false) {
        if (this.rowsArr.value[i].article.tax[0].value === 0) {
          taxCal = this.taxValidationsService.getContributorTax(contributorID, this.purchaseForm.value.tax);
        } else {
          taxCal = this.taxValidationsService.getContributorTax(contributorID, this.rowsArr.value[i].article.tax[0].value);
        }
      } else {
        taxCal = 0;
      }
      priceCal = this.rowsArr.value[i].price;
      quantityCal = this.rowsArr.value[i].quantity;
      discountCal = this.rowsArr.value[i].discount;
      if (taxCal) {
        if (discountCal != 0) {
          subsubtotal = quantityCal * priceCal;
          discountCal = (discountCal / 100) * subsubtotal;
          subsubtotal = subsubtotal - discountCal;
          if (this.rowsArr.value[i].article.tax[0].value === 0) {
            taxCal = (taxCal / 100) * subsubtotal;
          } else {
            taxCal = taxCal * subsubtotal;
          }
          this.rowsArr.value[i].subtotal = subsubtotal + taxCal;
          totalTax = totalTax + taxCal;
          totalWithoutTax = totalWithoutTax + subsubtotal;
        } else {
          subsubtotal = quantityCal * priceCal;
          if (this.rowsArr.value[i].article.tax[0].value === 0) {
            taxCal = (taxCal / 100) * subsubtotal;
          } else {
            taxCal = taxCal * subsubtotal;
          }
          this.rowsArr.value[i].subtotal = priceCal * quantityCal + taxCal;
          totalTax = totalTax + taxCal;
          totalWithoutTax = totalWithoutTax + subsubtotal;
        }
      } else if (discountCal != 0) {
        subsubtotal = quantityCal * priceCal;
        discountCal = (discountCal / 100) * subsubtotal;
        this.rowsArr.value[i].subtotal = subsubtotal - discountCal;
        totalWithoutTax = totalWithoutTax + subsubtotal - discountCal;
      } else {
        subsubtotal = quantityCal * priceCal;
        this.rowsArr.value[i].subtotal = subsubtotal;
      }
    }
    this.totalPurchase = 0;
    for (let i = 0; i < this.rowsArr.length; i++) {
      this.totalPurchase = this.totalPurchase + this.rowsArr.value[i].subtotal;
    }
    this.totalTax = totalTax;
    this.totalWithoutTax = this.totalPurchase - this.totalTax;
    if (this.companyObject.countries[0].name === "Colombia") {
      await this.calculateTaxes();
    }
  }

  async calculateTaxes() {
    const baseTax = await this.registerPurchaseService.getTaxBase();
    this.uvtValue = baseTax[0].value;
    this.withholdingTax =
      this.registerPurchaseService.calculationWithholdingTax(
        this.newProvider,
        this.totalWithoutTax,
        this.uvtValue,
        this.rowsArr,
        this.purchaseForm.value.clause
      );
    const resultCalculateIca =
      await this.registerPurchaseService.calculationIcaTax(
        this.customerCity,
        this.providerCity,
        this.newProvider,
        this.totalWithoutTax,
        this.uvtValue,
        this.rowsArr
      );
    this.icaTax = resultCalculateIca.icaTax;
    this.customerCity = resultCalculateIca.city;
    this.setCitiesForm(this.customerCity, this.providerCity, this.citiesList);
  }

  onCitiesCustomer(event: any) {
    this.customerCity = event.option.value.name;
    this.setCitiesForm(this.customerCity, this.providerCity, this.citiesList);
    this.calculation();
  }

  onCitiesProvider(event: any) {
    this.providerCity = event.option.value.name;
    this.setCitiesForm(this.customerCity, this.providerCity, this.citiesList);
    this.calculation();
  }

  displayFnArticle(article: any) {
    return article && article
    ? article.descripcion +
      new String(` - (`) +
      article.tax[0].value +
      new String(`)`)
    : undefined;
  }

  displayFnProvider(provider: any) {
    return provider && provider ? provider.nombre : undefined;
  }

  spinner(): void {
    this.spinnerService.show();
    setTimeout(() => {
      this.spinnerService.hide();
      this.calculation();
    }, 3000);
  }

  openDialog(i: number) {
    let dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: { filtro: this.newArticle, currentWarehouse: null },
    });
    dialogRef
    .afterClosed()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((result) => {
      if (result === "") {
        return;
      }
      this.rowsArr.at(i).get("article")?.setValue(result);
      this.calculation();
    });
  }

  taxValid(tax: boolean) {
    return tax === true ? true : false;
  }

  displayFnCity(city: any) {
    return city && city ? city.name : undefined;
  }

  _filterC(value: string): ICities[] {
    const filterValueC = value.toString().toLowerCase();
    return this.citiesList.filter((option) =>
      option.name.toLowerCase().toString().includes(filterValueC)
    );
  }

  compareWarehouse(item1: any, item2: any) {
    if (item1 === null || item2 === null) {
      return false;
    }
    return item1.id_almacen === item2.id_almacen;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
