<div [ngSwitch]="status" *ngIf="typeStepper == 1">
    <div *ngSwitchCase=1>
        <mat-horizontal-stepper [selectedIndex]="0" #stepper [ngSwitch]="status">
            <mat-step state="quote" [color]="" editable="false">
                <ng-container *ngIf="!isProspective">
                    <i>{{'Order' | translate}}</i>
                </ng-container>
                <ng-container *ngIf="isProspective">
                    <div fxLayout="row" fxLayoutAlign="center center" class="danger-icon">
                        <div matTooltip="{{'THE CUSTOMER IS PROSPECTIVE' | translate}}" matTooltipPosition="left">
                            <mat-icon>report_problem</mat-icon>
                        </div>
                        <div>
                            <i>{{'Quotation' | translate}}</i>
                        </div>
                    </div>
                </ng-container>
            </mat-step>
            <mat-step editable="false">
            </mat-step>
            <mat-step editable="false">
            </mat-step>
            <mat-step editable="false">
            </mat-step>
            <ng-template matStepperIcon="quote">
                <mat-icon>description</mat-icon>
            </ng-template>
        </mat-horizontal-stepper>
    </div>
    <div *ngSwitchCase=2>
        <mat-horizontal-stepper [selectedIndex]="1" #stepper [ngSwitch]="status">
            <mat-step state="check">
            </mat-step>
            <mat-step editable="false" state="reject">
                <i>{{'Rejected' | translate}}</i>
            </mat-step>
            <mat-step>
            </mat-step>
            <mat-step>
            </mat-step>
            <ng-template matStepperIcon="reject">
                <mat-icon>content_paste_off</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="check">
                <mat-icon>done</mat-icon>
            </ng-template>
        </mat-horizontal-stepper>
    </div>
    <div *ngSwitchCase=3>
        <mat-horizontal-stepper [selectedIndex]="2" #stepper [ngSwitch]="status">
            <mat-step state="check">
            </mat-step>
            <mat-step state="check">
            </mat-step>
            <mat-step editable="false" state="delivery">
                <i>{{'Delivery' | translate}}</i>
            </mat-step>
            <mat-step>
            </mat-step>
            <ng-template matStepperIcon="delivery">
                <mat-icon>local_shipping</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="check">
                <mat-icon>done</mat-icon>
            </ng-template>
        </mat-horizontal-stepper>
    </div>
    <div *ngSwitchCase=4>
        <mat-horizontal-stepper [selectedIndex]="3" #stepper [ngSwitch]="status">
            <mat-step state="check">
            </mat-step>
            <mat-step state="check">
            </mat-step>
            <mat-step state="check">
            </mat-step>
            <mat-step editable="false" state="invoice">
                <i>{{'Invoice' | translate}}</i>
            </mat-step>
            <ng-template matStepperIcon="invoice">
                <mat-icon>assignment_turned_in</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="check">
                <mat-icon>done</mat-icon>
            </ng-template>
        </mat-horizontal-stepper>
    </div>
</div>

<div [ngSwitch]="status" *ngIf="typeStepper == 2">
    <div *ngSwitchCase=10>
        <mat-horizontal-stepper [selectedIndex]="0" #stepper [ngSwitch]="status">
            <mat-step state="pend" [color]="" editable="false">
                <i>{{'Pending' | translate}}</i>
            </mat-step>
            <mat-step editable="false">
            </mat-step>
            <mat-step editable="false">
            </mat-step>
            <ng-template matStepperIcon="pend">
                <mat-icon>report</mat-icon>
            </ng-template>
        </mat-horizontal-stepper>
    </div>
    <div *ngSwitchCase=30>
        <mat-horizontal-stepper [selectedIndex]="2" #stepper [ngSwitch]="status">
            <mat-step state="check">
            </mat-step>
            <mat-step state="check">
            </mat-step>
            <mat-step [editable]="false" state="ok">
                <i>{{'Paid Admin' | translate}}</i>
            </mat-step>
            <ng-template matStepperIcon="ok">
                <mat-icon>done_all</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="check">
                <mat-icon>done</mat-icon>
            </ng-template>
        </mat-horizontal-stepper>
    </div>
</div>

<div [ngSwitch]="status" *ngIf="typeStepper == 3">
    <div *ngSwitchCase=1>
        <mat-horizontal-stepper [selectedIndex]="0" #stepper [ngSwitch]="status">
            <mat-step state="pending" [color]="" editable="false">
                <i>{{'Pending' | translate}}</i>
            </mat-step>
            <mat-step editable="false">
            </mat-step>
            <mat-step editable="false">
            </mat-step>
            <ng-template matStepperIcon="pending">
                <mat-icon>report_problem</mat-icon>
            </ng-template>
        </mat-horizontal-stepper>
    </div>
    <div *ngSwitchCase=2>
        <mat-horizontal-stepper [selectedIndex]="1" #stepper [ngSwitch]="status">
            <mat-step state="check">
            </mat-step>
            <mat-step editable="false" state="pending_v">
                <i>{{'Pending V.' | translate}}</i>
            </mat-step>
            <mat-step>
            </mat-step>
            <ng-template matStepperIcon="pending_v">
                <mat-icon>person</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="check">
                <mat-icon>done</mat-icon>
            </ng-template>
        </mat-horizontal-stepper>
    </div>
    <div *ngSwitchCase=3>
        <mat-horizontal-stepper [selectedIndex]="2" #stepper [ngSwitch]="status">
            <mat-step state="check">
            </mat-step>
            <mat-step state="check">
            </mat-step>
            <mat-step editable="false" state="cancelled">
                <i>{{'Cancelled' | translate}}</i>
            </mat-step>
            <ng-template matStepperIcon="cancelled">
                <mat-icon>done_all</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="check">
                <mat-icon>done</mat-icon>
            </ng-template>
        </mat-horizontal-stepper>
    </div>
</div>
