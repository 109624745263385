
<mat-tab-group #tabs mat-align-tabs="center" dynamicHeight="true" (selectedTabChange)="selectTab($event)">
  <!-- #enddocregion align-start -->
  
  <!--Quotation-->
  <mat-tab label="{{'Orders' | translate}} - {{'Quotations' | translate}}">
    <div class="row">
      <div class="col" style="margin-left: 20px;">
        <a (click)="openDialog(true)"><button mat-raised-button class="Warn">{{'Register' | translate}}</button></a>
      </div>
      <div class="col-6">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-paginator [length]="lengthBy1"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="pageEvent = $event; capturePage($event, 1)"
          aria-label="Select page">
          </mat-paginator>
        </div>
      </div>
      <div class="col" style="text-align: right; margin-right: 20px;">
        <app-search (search)="handleSearch($event, 1)"></app-search>
      </div>
    </div>
    
    <div class="alert alert-danger">
      {{'PLEASE VERIFY YOUR QUOTATIONS BEFORE CREATING THE INVOICE' | translate}}.!!!!!!
    </div>
    <div class="row"> 
      <div *ngFor="let quotation of QuotationListByStatus1" class="col-lg-3">
        <app-card [cardType]="1" [quotation]='quotation' [disableButton]="disableButton"></app-card>
      </div>
    </div>
  </mat-tab>

  <!--Rejected-->
  <mat-tab label="{{'Rejected' | translate}}">

    <div class="row">
      <div class="col" style="margin-left: 20px;">
        <a (click)="openDialog(true)"><button mat-raised-button class="Warn">{{'Register' | translate}}</button></a>
      </div>
      <div class="col-6">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-paginator [length]="lengthBy2"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="pageEvent = $event; capturePage($event, 2)"
          aria-label="Select page">
          </mat-paginator>
        </div>
      </div>
      <div class="col" style="text-align: right; margin-right: 20px;">
        <app-search (search)="handleSearch($event, 2)"></app-search>
      </div>
    </div>

    <div class="row">
      <div *ngFor="let quotation of QuotationListByStatus2" class="col-lg-3">
        <app-card [cardType]="1" [quotation]='quotation' ></app-card>
      </div>
    </div>
  </mat-tab>

  <!--Delivery-->
  <mat-tab label="{{'Deliveries' | translate}}">

    <div class="row">
      <div class="col" style="margin-left: 20px;">
        <a (click)="openDialog(true)"><button mat-raised-button class="Warn">{{'Register' | translate}}</button></a>
      </div>
      <div class="col-6">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-paginator [length]="lengthBy3"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="pageEvent = $event; capturePage($event, 3)"
          aria-label="Select page">
          </mat-paginator>
        </div>
      </div>
      <div class="col" style="text-align: right; margin-right: 20px;">
        <app-search (search)="handleSearch($event, 3)"></app-search>
      </div>
    </div>
      
    <div class="row">
      <div *ngFor="let quotation of QuotationListByStatus3" class="col-lg-3">
        <app-card [cardType]="1" [quotation]='quotation'></app-card>
      </div>
    </div>
  </mat-tab>

  <!--Invoice-->
  
  <mat-tab label="{{'Invoices' | translate}}">

    <div class="row">
      <div class="col" style="margin-left: 20px;">
        <a (click)="openDialog(true)"><button mat-raised-button class="Warn">{{'Register' | translate}}</button></a>
      </div>
      <div class="col-6">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-paginator [length]="lengthBy4"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="pageEvent = $event; capturePage($event, 4)"
          aria-label="Select page">
          </mat-paginator>
        </div>
      </div>
      <div class="col" style="text-align: right; margin-right: 20px;">
        <app-search (search)="handleSearch($event, 4)"></app-search>
      </div>
    </div>

    <div class="row">
      <div *ngFor="let quotation of QuotationListByStatus4" class="col-lg-3">
        <app-card [cardType]="1" [quotation]='quotation' [notes]="notes" [disableButton]="disableButton"></app-card>
      </div>
    </div>
    
  </mat-tab>

   <!--All-->
   <mat-tab  label="{{'All' | translate}}" >

    <div class="row">
      <div class="col" style="margin-left: 20px;">
        <a (click)="openDialog(true)"><button mat-raised-button class="Warn">{{'Register' | translate}}</button></a>
      </div>
      <div class="col-6">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-paginator [length]="lengthAll"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="pageEvent = $event; capturePage($event)"
          aria-label="Select page">
          </mat-paginator>
        </div>
      </div>
      <div class="col" style="text-align: right; margin-right: 20px;">
        <app-search (search)="handleSearch($event, 0)"></app-search>
      </div>
    </div>
 
    <div class="row">
      <div *ngFor="let quotation of QuotationListAll" class="col-lg-3">
        <app-card [cardType]="1" [quotation]='quotation' [notes]="notes" ></app-card>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

<ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
</ngx-spinner>
            