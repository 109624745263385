import { Injectable } from "@angular/core";
import { FormArray, UntypedFormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class ArticleValidationsService {
  constructor() {}

  checkDuplicateArticles(registerForm: UntypedFormGroup): boolean {
    const itemRows = registerForm.get("itemRows") as FormArray;
    const articles = itemRows.value.map((row: any) => parseInt(row.article.id_articulo));
    const articlesFound = {} as any;
    return articles.some((element: number) => {
      if (articlesFound[element]) {
        return true;
      } else {
        articlesFound[element] = true;
        return false;
      }
    });
  }
}
