
<div fxLayout="row" fxLayoutAlign="start center" class="filter-container">

    <div class="cashier-selector">
        <form [formGroup]="filterForm">
            <div class="autocomplete-container">                            
              <input class="form-control filter-by-seller" formControlName='cashier' name="cashier" placeholder="{{'Filter by cashier' | translate}}" 
              [matAutocomplete]="autoCashier">
              <mat-autocomplete class="select-options" #autoCashier="matAutocomplete" [displayWith]="displayFnCashier">
                  <mat-option class="multiline-mat-option" *ngFor="let cashier of cashierList"
                    [value]="cashier">
                    {{cashier.description}}
                  </mat-option>
              </mat-autocomplete>
            </div>
          </form>
    </div>
    
    <div>
        <mat-form-field appearance="fill">
            <mat-label>{{'Enter a date range' | translate }}</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker> 
            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{'Invalid start date' | translate}}</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{'Invalid end date' | translate}}</mat-error>
        </mat-form-field>
    </div>
</div>

<div class="header">
    <h3>{{'PLEASE SELECT THE REPORT TO DOWNLOAD' | translate}}</h3>
</div>
<div class="container">
    <div class="main">
        <table class="table table-bordered">
            <tbody>
                <tr *ngFor="let reports of reportList; let i = index">
                    <td>
                        {{i+1}}
                    </td>
                    <td>
                        {{reports | translate}}
                    </td>
                    <td>
                        <a class="goLink" (click)="select(reports)">
                            {{'Download' | translate}}
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div> 

<div hidden>
    <table class="table" id="cashflow">
        <thead>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
        </thead>
    
        <table class="table" id="dailysales">
            <thead>
                <tr>
                    <td>{{'Sales outlet' | translate}}</td>
                    <th>{{''}}</th>
                    <td>{{'Date' | translate}}</td>
                    <th>{{startDate}} - {{endDate}}</th>
                </tr>
            </thead>
        
            <thead>
                <tr>
                    <td>{{'Responsible' | translate}}</td>
                    <th>{{cashierName}}</th>
                    <td>{{'City' | translate}}</td>
                    <th>{{''}}</th>
                </tr>
            </thead>
    
            <thead>
                <tr>
                    <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
                </tr>
            </thead>
        
            <thead>
                <tr>
                    <th rowspan="2">{{'DATE' | translate}}</th>
                    <th rowspan="2">{{'N.º'}} {{'INVOICE' | translate}}</th>
                    <th rowspan="2">{{'N.º'}} {{'CASH REGISTER RECEIPT' | translate}}</th>
                    <th rowspan="2">{{'CUSTOMER' | translate}}</th>
                    <th rowspan="2">{{'INVOICE VALUE' | translate}}</th>
                    <th colspan="5" class="center-text">{{'PAYMENT METHODS' | translate}}</th>
                    <th colspan="2" class="center-text">{{'BANKERS' | translate}}</th>
                    <th rowspan="2">{{'TOTAL PAYMENT' | translate}}</th>
                </tr>
                <tr>
                    <th>{{'CASH' | translate}}</th>
                    <th>{{'CARD' | translate}}</th>
                    <th>{{'CONSIGNMENT TRANSFER' | translate}}</th>
                    <th>{{'CREDIT' | translate}}</th>
                    <th>{{'CROSS ADVANCE INVOICE' | translate}}</th>
                    <th>{{'BANK' | translate}}</th>
                    <th>{{'N.º'}} {{'OF APPROVAL' | translate}}</th>
                </tr>
            </thead>
        
            <tbody>
                <tr *ngFor="let item of invoiceDailyClosing.details">
                    <td>{{item.date}}</td>
                    <td>{{item.invoiceNumber}}</td>
                    <td></td>
                    <td>{{item.customerName}}</td>
                    <td>{{item.invoiceValue | thousandExcelPipe}}</td>
                    <td>{{item.cashPayment | thousandExcelPipe}}</td>
                    <td>{{item.cardPayment | thousandExcelPipe}}</td>
                    <td>{{item.bankPayment | thousandExcelPipe}}</td>
                    <td>{{item.invoiceValue - item.creditPayment | thousandExcelPipe}}</td>
                    <td>{{item.paymentCrossAdvance | thousandExcelPipe}}</td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td colspan="4">{{'TOTAL VALUES' | translate}}</td>
                    <td>{{invoiceDailyClosing.totalInvoices | thousandExcelPipe}}</td>
                    <td>{{invoiceDailyClosing.totalCashPayment | thousandExcelPipe}}</td>
                    <td>{{invoiceDailyClosing.totalCardPayment | thousandExcelPipe}}</td>
                    <td>{{invoiceDailyClosing.totalBankPayment | thousandExcelPipe}}</td>
                    <td>{{invoiceDailyClosing.totalCreditPayment | thousandExcelPipe}}</td>
                    <td>{{invoiceDailyClosing.totalCrossAdvance | thousandExcelPipe}}</td>
                    <td colspan="2">{{'GRAND TOTAL'}}</td>
                    <td>{{invoiceDailyClosing.totalPaid | thousandExcelPipe}}</td>
                </tr>
            </tbody>
        </table>
    
        <table>
            <thead>
                <tr>
                    <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
                </tr>
            </thead>
        </table>
        
        <table class="table" id="previousdeposits">
            <thead>
                <tr>
                    <th colspan="4">{{'PREVIOUS DAY`S SALES DEPOSITS' | translate}}</th>
                </tr>
            </thead>
            <thead>
                <tr>
                    <th>{{'DATE OF INVOICE' | translate}}</th>
                    <th>{{'DATE OF CONSIGNMENT' | translate}}</th>
                    <th>{{'AMOUNT' | translate}}</th>
                    <th>{{'N.º'}} {{'OF APPROPRIATION' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of previousDaysSaleDeposits.deposits">
                    <td></td>
                    <td>{{item.date}}</td>
                    <td>{{item.value | thousandExcelPipe}}</td>
                    <td>{{item.consignmentDescription}}</td>
                </tr>
            </tbody>
        </table>
    
        <table>
            <thead>
                <tr>
                    <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
                </tr>
            </thead>
        </table>
        
        <table class="table" id="cashdetails">
            <thead>
                <tr>
                    <th colspan="2">{{'DETAIL OF CASH RECEIVED' | translate}}</th>
                </tr>
            </thead>
            <thead>
                <tr>
                    <td>{{'PREVIOUS DAY`S CASH' | translate}}</td>
                    <th>{{cashPreviousDays | thousandExcelPipe}}</th>
                </tr>
            </thead>
            <thead>
                <tr>
                    <td>{{'CASH ON HAND TO BE RECORDED' | translate}}</td>
                    <th>{{allCashDetails.cashPending | thousandExcelPipe}}</th>
                </tr>
            </thead>
            <thead>
                <tr>
                    <td>{{'TOTAL DAY`S SALES CASH' | translate}}</td>
                    <th>{{allCashDetails.cashOfDay | thousandExcelPipe}}</th>
                </tr>
            </thead>
            <thead>
                <tr>
                    <td>{{'TOTAL CASH ON HAND' | translate}}</td>
                    <th>{{allCashDetails.cashOnHand | thousandExcelPipe}}</th>
                </tr>
            </thead>
            <thead *ngFor="let item of allCashDetails.otherCashInflows">
                <tr>
                    <td>{{item.documentDescription}}</td>
                    <th>{{item.value | thousandExcelPipe}}</th>
                </tr>
            </thead>
            <thead *ngFor="let item of allCashDetails.otherCashOutflows">
                <tr>
                    <td>{{item.documentDescription}}</td>
                    <th>{{item.value | thousandExcelPipe}}</th>
                </tr>
            </thead>
            <thead>
                <tr>
                    <td>{{'TOTAL TO BE CONSIGNED' | translate}}</td>
                    <th>{{allCashDetails.totalToBeConsigned | thousandExcelPipe}}</th>
                </tr>
            </thead>
        </table>
    
        <thead>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
        </thead>
    </table>
    
    <table class="table" id="cashclosing">
        <thead>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
        </thead>
        <thead>
            <tr>
                <th colspan="4">{{'CASH CLOSING' | translate}}</th>
            </tr>
        </thead>
        <thead>
            <tr>
                <td>{{'TOTAL DAILY SALES' | translate}}</td>
                <td>{{invoiceDailyClosing.totalPaid | thousandExcelPipe}}</td>
                <td>{{'TOTAL CASH CLOSING' | translate}}</td>
                <td>{{allCashDetails.totalToBeConsigned | thousandExcelPipe}}</td>
            </tr>
        </thead>
        <thead>
            <tr>
                <td>{{'BANK' | translate}}</td>
                <td>{{invoiceDailyClosing.totalBankPayment + invoiceDailyClosing.totalCardPayment | thousandExcelPipe}}</td>
            </tr>
        </thead>
    
        <thead>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
        </thead>
    
        <td>
            <tr>
                <th colspan="2">{{'Detail of cash closing' | translate}}</th>
            </tr>
            <tr>
                <td>{{'Datafono Sales' | translate}}</td>
                <td>{{invoiceDailyClosing.totalCardPayment | thousandExcelPipe}}</td>
            </tr>
            <tr>
                <td>{{'Cash Sales' | translate}}</td>
                <td>{{invoiceDailyClosing.totalCashPayment | thousandExcelPipe}}</td>
            </tr>
            <tr>
                <td>{{'Transfer' | translate}}</td>
                <td>{{invoiceDailyClosing.totalBankPayment | thousandExcelPipe}}</td>
            </tr>
            <tr>
                <td>{{'Of Credit' | translate}}</td>
                <td>{{invoiceDailyClosing.totalCreditPayment | thousandExcelPipe}}</td>
            </tr>
            <tr>
                <td>{{'Advance' | translate}}</td>
                <td>{{invoiceDailyClosing.totalCrossAdvance | thousandExcelPipe}}</td>
            </tr>
        </td>
    
        <thead>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
        </thead>
    
        <td>
            <tr>
                <th colspan="2">{{'Cash Inflows' | translate}}</th>
            </tr>
            <tr *ngFor="let item of allCashDetails.otherCashInflows">
                <td>{{item.documentDescription}}</td>
                <td>{{item.value | thousandExcelPipe}}</td>
            </tr>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
            <tr>
                <th colspan="2">{{'Cash Outflows' | translate}}</th>
            </tr>
            <tr *ngFor="let item of allCashDetails.otherCashOutflows">
                <td>{{item.documentDescription}}</td>
                <td>{{item.value | thousandExcelPipe}}</td>
            </tr>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
            <tr>
                <th colspan="2">{{'Cash Consigned' | translate}}</th>
            </tr>
            <tr *ngFor="let item of previousDaysSaleDeposits.deposits">
                <td>{{item.consignmentDescription}}</td>
                <td>{{item.value | thousandExcelPipe}}</td>
            </tr>
        </td>

        <thead>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
        </thead>
    </table>
    
    <table class="table" id="ticketing">
        <thead>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
        </thead>
        <thead>
            <tr>
                <th colspan="3">{{'CASH POSTS' | translate}}</th>
            </tr>
        </thead>
        <thead>
            <tr>
                <th colspan="3">{{'DENOMINATION' | translate}}</th>
            </tr>
        </thead>
    
        <tr>
            <td>{{'$100000'}}</td>
            <td>$</td>
            <td>$</td>
        </tr>
        <tr>
            <td>{{'$50000'}}</td>
            <td>$</td>
            <td>$</td>
        </tr>
        <tr>
            <td>{{'$20000'}}</td>
            <td>$</td>
            <td>$</td>
        </tr>
        <tr>
            <td>{{'$10000'}}</td>
            <td>$</td>
            <td>$</td>
        </tr>
        <tr>
            <td>{{'$5000'}}</td>
            <td>$</td>
            <td>$</td>
        </tr>
        <tr>
            <td>{{'$2000'}}</td>
            <td>$</td>
            <td>$</td>
        </tr>
        <tr>
            <td>{{'$1000'}}</td>
            <td>$</td>
            <td>$</td>
        </tr>
        <tr>
            <td>{{'$500'}}</td>
            <td>$</td>
            <td>$</td>
        </tr>
        <tr>
            <td>{{'$200'}}</td>
            <td>$</td>
            <td>$</td>
        </tr>
        <tr>
            <td>{{'$100'}}</td>
            <td>$</td>
            <td>$</td>
        </tr>
        <tr>
            <td>{{'$50'}}</td>
            <td>$</td>
            <td>$</td>
        </tr>
    
        <tr>
            <td colspan="2"></td>
            <td>$</td>
        </tr>
        <tr>
            <td colspan="2"></td>
            <td>$</td>
        </tr>
        <tr>
            <td colspan="2">{{'DIFFERENCE' | translate}}</td>
            <td>$</td>
        </tr>
        <thead>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
        </thead>
    </table>
    
    <table class="table" id="accounttransactions">
        <thead>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
        </thead>
        <thead>
            <tr>
                <th colspan="6">{{'ACCOUNT TRANSACTIONS' | translate}}</th>
            </tr>
        </thead>
        <thead>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
        </thead>
        <thead>
            <tr>
                <th>{{'VOUCHER ACCOUNT' | translate}}</th>
                <th>{{'DATE' | translate}}</th>
                <th>{{'DESCRIPTION' | translate}}</th>
                <th>{{'DEBITS' | translate}}</th>
                <th>{{'CREDITS' | translate}}</th>
                <th>{{'BALANCE' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{{'Cashirer' | translate}}</td>
                <td>{{startDate}} - {{endDate}}</td>
                <td>{{cashierName}}</td>
                <td>{{0}}</td>
                <td>{{0}}</td>
                <td>{{cashPreviousDays | thousandExcelPipe}}</td>
            </tr>
            <tr *ngFor="let item of invoiceDailyClosing.details">
                <td>{{item.invoiceNumber}}</td>
                <td>{{item.date}}</td>
                <td>{{item.customerName}}</td>
                <td>{{item.cashPayment | thousandExcelPipe}}</td>
                <td>{{0}}</td>
                <td>{{''}}</td>
            </tr>
            <tr *ngFor="let item of previousDaysSaleDeposits.deposits">
                <td>{{item.consignmentNumber}}</td>
                <td>{{item.date}}</td>
                <td>{{item.consignmentDescription}}</td>
                <td>{{0}}</td>
                <td>{{item.value | thousandExcelPipe}}</td>
                <td>{{''}}</td>
            </tr>
            <tr *ngFor="let item of otherCashOutflows">
                <td>{{item.documentNumber}}</td>
                <td>{{item.date}}</td>
                <td>{{item.documentDescription}}</td>
                <td>{{0}}</td>
                <td>{{item.value | thousandExcelPipe}}</td>
                <td>{{''}}</td>
            </tr>
            <tr *ngFor="let item of otherCashInflows">
                <td>{{item.documentNumber}}</td>
                <td>{{item.date}}</td>
                <td>{{item.documentDescription}}</td>
                <td>{{item.value | thousandExcelPipe}}</td>
                <td>{{0}}</td>
                <td>{{''}}</td>
            </tr>
            <tr>
                <td colspan="3">{{'GRAND TOTAL' | translate}}</td>
                <td>{{invoiceDailyClosing.totalCashPayment | thousandExcelPipe}}</td>
                <td>{{(previousDaysSaleDeposits.totalDeposits + allCashDetails.totalCashOutflows) + allCashDetails.totalCashInflows | thousandExcelPipe}}</td>
                <td>{{allCashDetails.totalToBeConsigned | thousandExcelPipe}}</td>
            </tr>
        </tbody>
        <thead>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
        </thead>
    </table>
    
    <table class="table" id="totals">
        <thead>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
        </thead>
    
        <table class="table" id="totalsbydepartament">
            <thead>
                <tr>
                    <th colspan="9">{{'TOTALS BY DEPARTAMENT' | translate}}</th>
                </tr>
            </thead>
        
            <thead>
                <tr>
                    <th>{{'Department code' | translate}}</th>
                    <th>{{'Department name' | translate}}</th>
                    <th>{{'Operation' | translate}}</th>
                    <th>{{'Rate' | translate}}</th>
                    <th>{{'Sales by rate' | translate}}</th>
                    <th>{{'Discounts/Charges' | translate}}</th>
                    <th>{{'Consumption tax' | translate}}</th>
                    <th>{{'Tax value' | translate}}</th>
                    <th>{{'Net Value' | translate}}</th>
                </tr>
            </thead>
        
            <tbody>
                <tr *ngFor="let item of totalsByDepartament.details">
                    <td>{{item.departamentCode}}</td>
                    <td>{{item.departamentDescription}}</td>
                    <td>{{item.operation}}</td>
                    <td>{{item.fee * 100}}</td>
                    <td>{{item.salesByFee | thousandExcelPipe}}</td>
                    <td>{{item.discountsOrFees | thousandExcelPipe}}</td>
                    <td>{{item.consumptionTax | thousandExcelPipe}}</td>
                    <td>{{item.taxValue | thousandExcelPipe}}</td>
                    <td>{{item.netValue | thousandExcelPipe}}</td>
                </tr>
                <tr>
                    <td colspan="4">{{'GRAND TOTAL' | translate}}</td>
                    <td>{{totalsByDepartament.totalSalesByFee | thousandExcelPipe}}</td>
                    <td>{{totalsByDepartament.totalDiscountsOrFees | thousandExcelPipe}}</td>
                    <td>{{totalsByDepartament.totalConsumptionTax | thousandExcelPipe}}</td>
                    <td>{{totalsByDepartament.totalTaxValue | thousandExcelPipe}}</td>
                    <td>{{totalsByDepartament.totalNetValue | thousandExcelPipe}}</td>
                </tr>
            </tbody>
        </table>
    
        <thead>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
        </thead>
        
        <table class="table" id="totalsbyrate">
            <thead>
                <tr>
                    <th colspan="4">{{'TOTALS BY RATE' | translate}}</th>
                </tr>
            </thead>
        
            <thead>
                <tr>
                    <th>{{'Operation' | translate}}</th>
                    <th>{{'Rate' | translate}}</th>
                    <th>{{'Sales by rate' | translate}}</th>
                    <th>{{'Tax value' | translate}}</th>
                </tr>
            </thead>
        
            <tbody>
                <tr>
                    <td>{{'Taxed' | translate}}</td>
                    <td>{{totalsByDepartament.averageTaxed}}</td>
                    <td>{{totalsByDepartament.salesTaxed | thousandExcelPipe}}</td>
                    <td>{{totalsByDepartament.totalTaxed | thousandExcelPipe}}</td>
                </tr>
                <tr>
                    <td>{{'Excluded' | translate}}</td>
                    <td>{{totalsByDepartament.averageExcluded}}</td>
                    <td>{{totalsByDepartament.salesExcluded | thousandExcelPipe}}</td>
                    <td>{{totalsByDepartament.totalExcluded | thousandExcelPipe}}</td>
                </tr>
                <tr>
                    <td>{{'Exempt' | translate}}</td>
                    <td>{{totalsByDepartament.averageExempt}}</td>
                    <td>{{totalsByDepartament.salesExcempt | thousandExcelPipe}}</td>
                    <td>{{totalsByDepartament.totalExempt | thousandExcelPipe}}</td>
                </tr>
            </tbody>
        </table>
    
        <thead>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
        </thead>
        
        <table class="table" id="totalsbypaymentmethod">
            <thead>
                <tr>
                    <th colspan="6">{{'TOTALS BY PAYMENT METHODS' | translate}}</th>
                </tr>
            </thead>
        
            <thead>
                <tr>
                    <th>{{'Payment' | translate}}:</th>
                    <th>{{'Cash' | translate}}</th>
                    <th>{{'N.º'}}{{'Transactions' | translate}}:</th>
                    <td>{{transactionNumbers.cash}}</td>
                    <th>{{'Total value' | translate}}:</th>
                    <td>{{invoiceDailyClosing.totalCashPayment | thousandExcelPipe}}</td>
                </tr>
            </thead>
        
            <thead>
                <tr>
                    <th>{{'Payment' | translate}}:</th>
                    <th>{{'Bank check' | translate}}</th>
                    <th>{{'N.º'}}{{'Transactions' | translate}}:</th>
                    <td>{{0}}</td>
                    <th>{{'Total value' | translate}}:</th>
                    <td>{{0}}</td>
                </tr>
            </thead>
        
            <thead>
                <tr>
                    <th>{{'Payment' | translate}}:</th>
                    <th>{{'Card' | translate}}</th>
                    <th>{{'N.º'}}{{'Transactions' | translate}}:</th>
                    <td>{{transactionNumbers.card}}</td>
                    <th>{{'Total value' | translate}}:</th>
                    <td>{{invoiceDailyClosing.totalCardPayment | thousandExcelPipe}}</td>
                </tr>
            </thead>
        
            <thead>
                <tr>
                    <th>{{'Payment' | translate}}:</th>
                    <th>{{'Of Credit' | translate}}</th>
                    <th>{{'N.º'}}{{'Transactions' | translate}}:</th>
                    <td>{{transactionNumbers.credit}}</td>
                    <th>{{'Total value' | translate}}:</th>
                    <td>{{invoiceDailyClosing.totalCreditPayment | thousandExcelPipe}}</td>
                </tr>
            </thead>
        
            <thead>
                <tr>
                    <th>{{'Payment' | translate}}:</th>
                    <th>{{'Bonds' | translate}}</th>
                    <th>{{'N.º'}}{{'Transactions' | translate}}:</th>
                    <td>{{0}}</td>
                    <th>{{'Total value' | translate}}:</th>
                    <td>{{0}}</td>
                </tr>
            </thead>
        
            <thead>
                <tr>
                    <th>{{'Payment' | translate}}:</th>
                    <th>{{'Vouchers' | translate}}</th>
                    <th>{{'N.º'}}{{'Transactions' | translate}}:</th>
                    <td>{{0}}</td>
                    <th>{{'Total value' | translate}}:</th>
                    <td>{{0}}</td>
                </tr>
            </thead>
        
            <thead>
                <tr>
                    <th>{{'Payment' | translate}}:</th>
                    <th>{{'Advances' | translate}}</th>
                    <th>{{'N.º'}}{{'Transactions' | translate}}:</th>
                    <td>{{transactionNumbers.advances}}</td>
                    <th>{{'Total value' | translate}}:</th>
                    <td>{{invoiceDailyClosing.totalCrossAdvance | thousandExcelPipe}}</td>
                </tr>
            </thead>
        
            <thead>
                <tr>
                    <th>{{'Payment' | translate}}:</th>
                    <th>{{'Bank' | translate}}</th>
                    <th>{{'N.º'}}{{'Transactions' | translate}}:</th>
                    <td>{{transactionNumbers.bank}}</td>
                    <th>{{'Total value' | translate}}:</th>
                    <td>{{invoiceDailyClosing.totalBankPayment | thousandExcelPipe}}</td>
                </tr>
            </thead>
        
            <thead>
                <tr>
                    <th>{{'Payment' | translate}}:</th>
                    <th>{{'Other' | translate}}</th>
                    <th>{{'N.º'}}{{'Transactions' | translate}}:</th>
                    <td>{{0}}</td>
                    <th>{{'Total value' | translate}}:</th>
                    <td>{{0}}</td>
                </tr>
            </thead>
        </table>
    
        <thead>
            <tr>
                <td colspan="13">---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</td>
            </tr>
        </thead>
    </table>
</div>
