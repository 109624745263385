
<mat-tab-group #tabs mat-align-tabs="center" dynamicHeight="true">

    <mat-tab label="{{'Pending' | translate}}">
      <div class="row">
        <div class="col" style="margin-left: 20px;">
          <button [disabled]="disableButton" mat-raised-button [matMenuTriggerFor]="menu">{{'Config' | translate}}  <mat-icon class="black-icon">settings</mat-icon></button>
            <mat-menu #menu="matMenu">
              <a (click)="openDialogMails(true, listMails, 'accountsPayable')"><button mat-menu-item class="Warn">{{'Mails List' | translate}}</button></a>
              <a (click)="openDialogBanks(true, listBanks, 'accountsPayable')"><button mat-menu-item class="Warn">{{'Bank List' | translate}}</button></a>
              <a (click)="openDialogCashiers(true, listCashiers, 'accountsPayable')"><button mat-menu-item class="Warn">{{'Cashiers List' | translate}}</button></a>
            </mat-menu>
        </div>
        <div class="col-6">
          <mat-paginator [length]="this.PurchasesListByStatus10.length" [showFirstLastButtons]="true" [pageSize]="4"
            [pageSizeOptions]="[4,8,12]" (page)="OnPageChangeByS10($event)"></mat-paginator>
        </div>
        <div class="col" style="text-align: right; margin-right: 20px;">
          <app-search (search)="handleSearchS10($event)"></app-search>
        </div>
      </div>

      <div class="row">
        <div *ngFor="let purchase of pageSliceByS10" class="col-lg-3">
          <app-purchase-card [purchaseInvoice]="purchase" [cardType]="2"></app-purchase-card>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="{{'Paid Admin' | translate}}">
      <div class="row">
        <div class="col" style="margin-left: 20px;">
          <button [disabled]="disableButton" mat-raised-button [matMenuTriggerFor]="menu">{{'Config' | translate}}  <mat-icon class="black-icon">settings</mat-icon></button>
            <mat-menu #menu="matMenu">
              <a (click)="openDialogMails(true, listMails, 'accountsPayable')"><button mat-menu-item class="Warn">{{'Mails List' | translate}}</button></a>
              <a (click)="openDialogBanks(true, listBanks, 'accountsPayable')"><button mat-menu-item class="Warn">{{'Bank List' | translate}}</button></a>
              <a (click)="openDialogCashiers(true, listCashiers, 'accountsPayable')"><button mat-menu-item class="Warn">{{'Cashiers List' | translate}}</button></a>
            </mat-menu>
        </div>
        <div class="col-6">
          <mat-paginator [length]="this.PurchasesListByStatus30.length" [showFirstLastButtons]="true" [pageSize]="4"
            [pageSizeOptions]="[4,8,12]" (page)="OnPageChangeByS30($event)"></mat-paginator>
        </div>
        <div class="col" style="text-align: right; margin-right: 20px;">
          <app-search (search)="handleSearchS30($event)"></app-search>
        </div>
      </div>

      <div class="row">
        <div *ngFor="let purchase of pageSliceByS30" class="col-lg-3">
          <app-purchase-card [purchaseInvoice]="purchase" [cardType]="2"></app-purchase-card>
        </div>
      </div>
    </mat-tab>

  </mat-tab-group>

  <ngx-spinner type="ball-scale-multiple" template="<img src='../../../../../../assets/gif/V1-Insight-Icon-for-gif-2.gif' />" bdColor="rgba(51,51,51,0.8)">
    <p style="font-size: 20px; color: #ffffff">{{'Loading...' | translate}}</p>
  </ngx-spinner>
              