import { Injectable } from "@angular/core";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { IPurchasesInvoices } from "src/app/@interfaces/purchaseInvoice.interface";
import { IPurchasePayments } from "src/app/@interfaces/payments.interface";
import { PortfolioService } from "src/app/@pages/portfolio/portfolio.service";
import { ImageConverseService } from "../imageConverse.service";
import { CookieAuthService } from "src/app/@shared/storage-variables/cookie-auth.service";
(<any>pdfMake).addVirtualFileSystem(pdfFonts);

@Injectable({
  providedIn: "root",
})
export class CreatePDFPurchasesService {
  totalPaid: number = 0;

  constructor(
    private portfolio: PortfolioService,
    private translate: TranslateService,
    private imageConverseService: ImageConverseService,
    private cookieAuthService: CookieAuthService
  ) {}

  async createPDF(open: boolean, state: string, purchases: IPurchasesInvoices, image: any, paymentDescription: string) {
    const companyObject = this.cookieAuthService.getCompanyObject;
    const company = companyObject!.name;
    const payments = await this.setPayments(purchases);
    if (payments.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant("No payment was made on this invoice!"),
      });
      return;
    }
    if (!(purchases.payment)) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant("There is no payment on this invoice!"),
      });
      return;
    }
    let paymentType = this.setPaymentType(payments);
    const items: any = await this.getPayments(payments);
    const watermark: string = state;
    const pdfDefinition: any = {
      watermark: {
        text: watermark,
        color: "grey",
        opacity: 0.3,
        bold: true,
        italics: false,
        fontSize: 120,
      },
      content: [
        {
          style: "tableExample",
          table: {
            widths: ["*", "*", "*"],
            heights: [25, 25, 25],
            body: [
              [
                {
                  image: await this.imageConverseService.getBase64ImageFromURL(
                    image
                  ),
                  width: 100,
                  height: 80,
                  rowSpan: 3,
                  border: [false, false, false, false],
                  alignment: "center",
                },
                {
                  rowSpan: 3,
                  text: `${company}`,
                  border: [false, false, false, false],
                  alignment: "center",
                },
                {
                  rowSpan: 3,
                  text: `Recibo\n ${
                    purchases.contpurchase![0].written_invoice
                  }`,
                  border: [false, false, false, false],
                  alignment: "center",
                },
              ],
              ["", "", ""],
              ["", "", ""],
            ],
          },
        },
        {
          style: "tableExample",
          color: "#444",
          table: {
            widths: ["auto", "*", "auto", "auto", "auto", "auto"],
            headerRows: 2,
            body: [
              [
                {
                  style: "header",
                  border: [true, true, false, false],
                  text: "Recibimos de",
                },
                {
                  style: "textStyle",
                  border: [false, true, true, true],
                  colSpan: 3,
                  text: `${purchases.provider[0].nombre}`,
                },
                "",
                "",
                { style: "header", text: "Fecha Recibo" },
                { style: "header", text: "Forma Pago" },
              ],
              [
                {
                  style: "header",
                  border: [true, false, false, false],
                  text: "NIT",
                },
                {
                  style: "textStyle",
                  border: [false, true, true, true],
                  text: `${purchases.provider[0].doc_nit}`,
                },
                {
                  style: "header",
                  border: [true, true, false, false],
                  text: "Telefono",
                },
                {
                  style: "textStyle",
                  border: [false, true, true, true],
                  text: `${purchases.provider[0].telefono}`,
                },
                { style: "textStyle", text: `${purchases.createdAt}` },
                {
                  style: "textStyle",
                  text: `${this.translate.instant(
                    paymentType
                  )} ${paymentDescription}`,
                },
              ],
              [
                {
                  style: "header",
                  border: [true, false, false, true],
                  text: "Direccion",
                },
                {
                  style: "textStyle",
                  border: [false, true, true, true],
                  text: `${purchases.provider[0].direccion}`,
                },
                {
                  style: "header",
                  border: [true, false, false, true],
                  text: "Ciudad",
                },
                {
                  style: "textStyle",
                  border: [false, true, true, true],
                  text: `${purchases.provider[0].city}`,
                },
                { style: "header", text: "-" },
                { style: "textStyle", text: `` },
              ],
            ],
          },
          layout: {
            hLineColor: function (i: any, node: any) {
              return "grey";
            },
            vLineColor: function (i: any, node: any) {
              return "grey";
            },
          },
        },
        {
          style: "tableExample",
          table: {
            widths: ["*", "auto"],
            body: [
              [
                { style: "header", text: "El valor de" },
                { style: "textStyle", text: `${purchases.purchases[0].total}` },
              ],
            ],
          },
          layout: {
            hLineColor: function (i: any, node: any) {
              return "grey";
            },
            vLineColor: function (i: any, node: any) {
              return "grey";
            },
          },
        },
        {
          style: "tableExample",
          table: {
            widths: [70, 70, 70, "*", "auto"],
            body: items,
          },
          layout: {
            hLineColor: function (i: any, node: any) {
              return "grey";
            },
            vLineColor: function (i: any, node: any) {
              return "grey";
            },
          },
        },
        {
          style: "tableExample",
          table: {
            widths: ["auto", "*"],
            body: [
              [
                {
                  border: [true, true, false, true],
                  style: "header",
                  text: "Obervaciones:",
                },
                {
                  border: [false, true, true, true],
                  style: "textStyle",
                  text: "",
                },
              ],
            ],
          },
          layout: {
            hLineColor: function (i: any, node: any) {
              return "grey";
            },
            vLineColor: function (i: any, node: any) {
              return "grey";
            },
          },
        },
        {
          style: "tableExample",
          table: {
            widths: ["*", "*"],
            heights: [25, 25],
            body: [
              [
                {
                  style: "header",
                  rowSpan: 3,
                  text: "\n\n\n_________________________________ \nFirma Elaborado",
                  border: [true, true, false, true],
                  alignment: "center",
                },
                {
                  style: "header",
                  rowSpan: 3,
                  text: "\n\n\n_________________________________ \nFirma Recibido",
                  border: [false, true, true, true],
                  alignment: "center",
                },
              ],
              ["", ""],
              ["", ""],
            ],
          },
          layout: {
            hLineColor: function (i: any, node: any) {
              return "grey";
            },
            vLineColor: function (i: any, node: any) {
              return "grey";
            },
          },
        },
      ],
      styles: {
        header: {
          fontSize: 10,
          bold: true,
          color: "black",
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 10,
          color: "black",
        },
        textStyle: {
          fontSize: 10,
          color: "#363636",
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };
    const pdf = pdfMake.createPdf(pdfDefinition);
    if (open === true) {
      pdf.open();
    }
    return pdf;
  }

  setPayments(purchases: IPurchasesInvoices) {
    return new Promise<IPurchasePayments[]>((resolve, reject) => {
      let purchasePays: IPurchasePayments[] = [];
      this.portfolio
      .getPaymenPurchase(purchases.id_invoice!)
      .subscribe((result) => {
        purchasePays = result;
        resolve(purchasePays);
      });
    });
  }

  setPaymentType(payment: IPurchasePayments[]) {
    let paymentType: string[] = [];
    payment.forEach((element) => {
      if (element.paymentDetails.type_payment === 1) {
        paymentType.push(this.translate.instant("Cash"));
      }
      if (element.paymentDetails.type_payment === 2) {
        paymentType.push(this.translate.instant("Current account consignment"));
      }
      if (element.paymentDetails.type_payment === 3) {
        paymentType.push(this.translate.instant("Savings account consignment"));
      }
    });
    return paymentType.join(", ");
  }

  async getPayments(payments: IPurchasePayments[]) {
    const getObjs = (payments: IPurchasePayments[]) => {
      return new Promise<any[]>((resolve, reject) => {
        let array: any = [];
        this.totalPaid = 0;
        payments.forEach((element: any) => {
          const obj1 = {
            border: [true, false, false, false],
            style: "textStyle",
            text: this.translate.instant(element.type),
          };
          const obj2 = {
            border: [false, false, false, false],
            style: "textStyle",
            text: element.identifier,
          };
          const obj3 = {
            border: [false, false, false, false],
            style: "textStyle",
            text: element.paymentDetails.id_ref,
          };
          const obj4 = {
            border: [false, false, false, false],
            style: "" + "textStyle",
            text: element.date,
          };
          const obj5 = {
            border: [false, false, true, false],
            style: "textStyle",
            text: "$" + element.value,
          };
          array.push([obj1, obj2, obj3, obj4, obj5]);
          resolve(array);
          this.totalPaid = this.totalPaid + parseFloat(element.value);
        });
      });
    };
    const data: any = await getObjs(payments);
    const header1 = [
      {
        border: [true, true, false, false],
        text: "Concepto",
        style: "tableHeader",
        colSpan: 4,
        alignment: "center",
      },
      {},
      {},
      {},
      {
        border: [false, true, true, false],
        text: "Valor",
        style: "tableHeader",
        alignment: "center",
      },
    ];
    const header2 = [
      {
        border: [true, false, false, true],
        text: "Total",
        style: "tableHeader",
        colSpan: 4,
        alignment: "right",
      },
      "",
      "",
      "",
      {
        border: [false, false, true, true],
        style: "textStyle",
        text: "$" + `${this.totalPaid}`,
      },
    ];
    data.unshift(header1);
    data.push(header2);
    return data;
  }
}
