import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IOtherCashOutflows } from "src/app/@interfaces/Reports/cash-flows.interface";
import { ICashReceivedDetails } from "src/app/@interfaces/Reports/cash-received.interface";
import {
  IDailyInvoicePaymentDetails,
  IDailyInvoicePayments,
} from "src/app/@interfaces/Reports/daily-invoice-payments.interface";
import { IPreviousDaysSaleDepositDetails, IPreviousDaysSaleDeposits } from "src/app/@interfaces/Reports/previus-sale-deposits.interface";
import { ITotalsByDepartament, ITotalsByDepartamentDetails } from "src/app/@interfaces/Reports/totals-by-departament.interface";
import {
  IInvoicePayments,
  IPurchasePayments,
} from "src/app/@interfaces/payments.interface";
import { IQuota } from "src/app/@interfaces/quotation.interface";
import { ITransfers } from "src/app/@interfaces/transfers.interface";
import { IVentaQuery } from "src/app/@interfaces/venta.interface";
import { OperationalReportingService } from "src/app/@pages/reports/pages/operational-reporting/operational-reporting.service";
import { TransferFiltersService } from "../filters/transfer-filters.service";
import { ITreasury } from "src/app/@interfaces/treasury.interface";

@Injectable({
  providedIn: "root",
})
export class CalculateOperationalReportingService {
  constructor(
    private translate: TranslateService,
    private operationalReportingService: OperationalReportingService,
    private transferFiltersService: TransferFiltersService
  ) {}

  calculateDailyClosing(invoicePayments: IInvoicePayments[]) {
    let dailyClosing = {} as IDailyInvoicePayments;
    let totalsByDepartament = {} as ITotalsByDepartament;
    let totalPaid: number = 0;
    const dailyPayments = this.calculateDailyInvoicePayments(invoicePayments);
    totalPaid = this.calculateTotalPaid(totalPaid, dailyPayments);
    dailyClosing = {
      details: this.operationalReportingService.combineDetailsByInvoice(
        dailyPayments.daily.dailyDetails
      ),
      totalInvoices: dailyPayments.daily.totalInvoices,
      totalCashPayment: dailyPayments.daily.totalCashPayment,
      totalCardPayment: dailyPayments.daily.totalCardPayment,
      totalBankPayment: dailyPayments.daily.totalBankPayment,
      totalCreditPayment: dailyPayments.daily.totalCreditPayment,
      totalCrossAdvance: dailyPayments.daily.totalCrossAdvance,
      totalPaid: totalPaid,
    };
    totalsByDepartament = {
      totalSalesByFee: dailyPayments.byDepartament.totalSalesByFee,
      totalDiscountsOrFees: dailyPayments.byDepartament.totalDiscountsOrFees,
      totalConsumptionTax: dailyPayments.byDepartament.totalConsumptionTax,
      totalTaxValue: dailyPayments.byDepartament.totalTaxValue,
      totalNetValue: dailyPayments.byDepartament.totalNetValue,
      details: this.operationalReportingService.combineDetailsByDepartament(
        dailyPayments.byDepartament.totalsByDepartamentDetails
      ),
      salesTaxed: dailyPayments.byDepartament.salesTaxed,
      salesExcluded: dailyPayments.byDepartament.salesExcluded,
      salesExcempt: dailyPayments.byDepartament.salesExcempt,
      averageTaxed: dailyPayments.byDepartament.averageTaxed,
      averageExcluded: dailyPayments.byDepartament.averageExcluded,
      averageExempt: dailyPayments.byDepartament.averageExempt,
      totalTaxed: dailyPayments.byDepartament.totalTaxed,
      totalExcluded: dailyPayments.byDepartament.totalExcluded,
      totalExempt: dailyPayments.byDepartament.totalExempt,
    };
    return {
      dailyClosing: dailyClosing,
      totalsByDepartament: totalsByDepartament,
    };
  }

  calculateTotalPaid(totalPaid: number, dailyPayments: any) {
    totalPaid += dailyPayments.daily.totalCashPayment;
    totalPaid += dailyPayments.daily.totalCardPayment;
    totalPaid += dailyPayments.daily.totalBankPayment;
    totalPaid += dailyPayments.daily.totalCrossAdvance;
    return totalPaid;
  }

  calculateDailyInvoicePayments(invoicePayments: IInvoicePayments[]) {
    let dailyDetails: IDailyInvoicePaymentDetails[] = [];
    let totalsObject = {} as IDailyInvoicePayments;
    totalsObject = this.initTotalsObject(totalsObject);
    let totalsByDepartamentDetails: ITotalsByDepartamentDetails[] = [];
    let totalsByDepartamentObject = {} as ITotalsByDepartament;
    totalsByDepartamentObject = this.initTotalsByDepartamentObject(
      totalsByDepartamentObject
    );
    const allCalculatedData = this.calculateAllDailyInvoiceData(
      invoicePayments,
      dailyDetails,
      totalsObject,
      totalsByDepartamentDetails,
      totalsByDepartamentObject
    );
    return this.createFinalDailyObject(
      allCalculatedData.dailyDetails,
      allCalculatedData.totalsObject,
      allCalculatedData.totalsByDepartamentDetails,
      allCalculatedData.totalsByDepartamentObject
    );
  }

  calculateAllDailyInvoiceData(
    invoicePayments: IInvoicePayments[],
    dailyDetails: IDailyInvoicePaymentDetails[],
    totalsObject: IDailyInvoicePayments,
    totalsByDepartamentDetails: ITotalsByDepartamentDetails[],
    totalsByDepartamentObject: ITotalsByDepartament
  ) {
    invoicePayments.forEach((element) => {
      const validatedPayments = this.validateDailyPayments(element);
      const detailsObject = this.createDailyDetailsObject(
        element,
        validatedPayments
      );
      detailsObject.creditPayment =
        this.calculateSingleCardPayment(detailsObject);
      dailyDetails.push(detailsObject);
      totalsObject = this.calculateDailyTotals(totalsObject, detailsObject);
      const allTotalsByDepartament = this.getTotalsByDepartament(
        element,
        totalsByDepartamentDetails,
        totalsByDepartamentObject
      );
      totalsByDepartamentDetails =
        allTotalsByDepartament.totalsByDepartamentDetails;
      totalsByDepartamentObject =
        allTotalsByDepartament.totalsByDepartamentObject;
    });
    return {
      dailyDetails: dailyDetails,
      totalsObject: totalsObject,
      totalsByDepartamentDetails: totalsByDepartamentDetails,
      totalsByDepartamentObject: totalsByDepartamentObject,
    };
  }

  getTotalsByDepartament(
    element: IInvoicePayments,
    totalsByDepartamentDetails: ITotalsByDepartamentDetails[],
    totalsByDepartamentObject: ITotalsByDepartament
  ) {
    element.factura.venta.forEach((sale) => {
      const totalsByDepartament = this.calculateTaxedSale(
        element.factura,
        sale
      );
      totalsByDepartamentDetails.push(totalsByDepartament);
      totalsByDepartamentObject = this.calculateTotalsByDepartament(
        totalsByDepartamentObject,
        totalsByDepartament
      );
      totalsByDepartamentObject = this.calculateTotalsByTaxes(
        totalsByDepartamentObject,
        totalsByDepartament
      );
    });
    return {
      totalsByDepartamentDetails: totalsByDepartamentDetails,
      totalsByDepartamentObject: totalsByDepartamentObject,
    };
  }

  initTotalsObject(totalsObject: IDailyInvoicePayments) {
    totalsObject.totalInvoices = 0;
    totalsObject.totalCashPayment = 0;
    totalsObject.totalCardPayment = 0;
    totalsObject.totalBankPayment = 0;
    totalsObject.totalCreditPayment = 0;
    totalsObject.totalCrossAdvance = 0;
    return totalsObject;
  }

  initTotalsByDepartamentObject(
    totalsByDepartamentObject: ITotalsByDepartament
  ) {
    totalsByDepartamentObject.totalSalesByFee = 0;
    totalsByDepartamentObject.totalDiscountsOrFees = 0;
    totalsByDepartamentObject.totalConsumptionTax = 0;
    totalsByDepartamentObject.totalTaxValue = 0;
    totalsByDepartamentObject.totalNetValue = 0;
    totalsByDepartamentObject.salesTaxed = 0;
    totalsByDepartamentObject.salesExcluded = 0;
    totalsByDepartamentObject.salesExcempt = 0;
    totalsByDepartamentObject.averageTaxed = 0;
    totalsByDepartamentObject.averageExcluded = 0;
    totalsByDepartamentObject.averageExempt = 0;
    totalsByDepartamentObject.totalTaxed = 0;
    totalsByDepartamentObject.totalExcluded = 0;
    totalsByDepartamentObject.totalExempt = 0;
    return totalsByDepartamentObject;
  }

  calculateSingleCardPayment(detailsObject: IDailyInvoicePaymentDetails) {
    const summedValues = (detailsObject.cashPayment +
      detailsObject.cardPayment +
      detailsObject.bankPayment +
      detailsObject.paymentCrossAdvance)
    return summedValues
  }

  calculateDailyTotals(
    totalsObject: IDailyInvoicePayments,
    detailsObject: IDailyInvoicePaymentDetails
  ) {
    totalsObject.totalInvoices += detailsObject.invoiceValue;
    totalsObject.totalCashPayment += detailsObject.cashPayment;
    totalsObject.totalCardPayment += detailsObject.cardPayment;
    totalsObject.totalBankPayment += detailsObject.bankPayment;
    totalsObject.totalCreditPayment += detailsObject.creditPayment;
    totalsObject.totalCrossAdvance += detailsObject.paymentCrossAdvance;
    return totalsObject;
  }

  calculateTotalsByDepartament(
    totalsByDepartamentObject: ITotalsByDepartament,
    totalsByDepartament: ITotalsByDepartamentDetails
  ) {
    totalsByDepartamentObject.totalSalesByFee += totalsByDepartament.salesByFee;
    totalsByDepartamentObject.totalDiscountsOrFees +=
      totalsByDepartament.discountsOrFees;
    totalsByDepartamentObject.totalConsumptionTax +=
      totalsByDepartament.consumptionTax;
    totalsByDepartamentObject.totalTaxValue += totalsByDepartament.taxValue;
    totalsByDepartamentObject.totalNetValue += totalsByDepartament.netValue;
    return totalsByDepartamentObject;
  }

  calculateTotalsByTaxes(
    totalsByDepartamentObject: ITotalsByDepartament,
    totalsByDepartament: ITotalsByDepartamentDetails
  ) {
    totalsByDepartamentObject.salesTaxed +=
      this.operationalReportingService.validTaxType(
        totalsByDepartament,
        "Taxed"
      );
    totalsByDepartamentObject.salesExcluded +=
      this.operationalReportingService.validTaxType(
        totalsByDepartament,
        "Excluded"
      );
    totalsByDepartamentObject.salesExcempt += 0;
    totalsByDepartamentObject.totalTaxed +=
      this.operationalReportingService.getTaxTypeValue(totalsByDepartament);
    totalsByDepartamentObject.totalExcluded += 0;
    totalsByDepartamentObject.totalExempt += 0;
    0;
    return totalsByDepartamentObject;
  }

  createFinalDailyObject(
    dailyDetails: IDailyInvoicePaymentDetails[],
    totalsObject: IDailyInvoicePayments,
    totalsByDepartamentDetails: ITotalsByDepartamentDetails[],
    totalsByDepartamentObject: ITotalsByDepartament
  ) {
    return {
      daily: {
        dailyDetails: dailyDetails,
        totalInvoices: totalsObject.totalInvoices,
        totalCashPayment: totalsObject.totalCashPayment,
        totalCardPayment: totalsObject.totalCardPayment,
        totalBankPayment: totalsObject.totalBankPayment,
        totalCreditPayment: totalsObject.totalCreditPayment,
        totalCrossAdvance: totalsObject.totalCrossAdvance,
        totalPaid: 0,
      },
      byDepartament: {
        totalSalesByFee: totalsByDepartamentObject.totalSalesByFee,
        totalDiscountsOrFees: totalsByDepartamentObject.totalDiscountsOrFees,
        totalConsumptionTax: totalsByDepartamentObject.totalConsumptionTax,
        totalTaxValue: totalsByDepartamentObject.totalTaxValue,
        totalNetValue: totalsByDepartamentObject.totalNetValue,
        totalsByDepartamentDetails: totalsByDepartamentDetails,
        salesTaxed: totalsByDepartamentObject.salesTaxed,
        salesExcluded: totalsByDepartamentObject.salesExcluded,
        salesExcempt: totalsByDepartamentObject.salesExcempt,
        averageTaxed: this.operationalReportingService.calculateAverageTaxed(
          totalsByDepartamentObject.salesTaxed,
          totalsByDepartamentObject.totalTaxed
        ),
        averageExcluded: this.operationalReportingService.calculateAverageTaxed(
          totalsByDepartamentObject.salesExcluded,
          totalsByDepartamentObject.totalExcluded
        ),
        averageExempt: this.operationalReportingService.calculateAverageTaxed(
          totalsByDepartamentObject.salesExcempt,
          totalsByDepartamentObject.totalExempt
        ),
        totalTaxed: totalsByDepartamentObject.totalTaxed,
        totalExcluded: totalsByDepartamentObject.totalExcluded,
        totalExempt: totalsByDepartamentObject.totalExempt,
      },
    };
  }

  validateDailyPayments(element: IInvoicePayments) {
    const paymentWidhAdvance =
      this.operationalReportingService.verityPaymentWidhAdvance(element);
    const bankIsCard =
      this.operationalReportingService.verifyBankIsCard(element);
    const paymentType =
      this.operationalReportingService.verifyPaymentType(element);
    const cashPayment = paymentWidhAdvance || !paymentType ? 0 : element.value;
    const bankPayment =
      bankIsCard || paymentWidhAdvance || paymentType ? 0 : element.value;
    const cardPayment = bankIsCard ? element.value : 0;
    const paymentCrossAdvance = paymentWidhAdvance ? element.value : 0;
    return {
      cashPayment: cashPayment,
      cardPayment: cardPayment,
      bankPayment: bankPayment,
      paymentCrossAdvance: paymentCrossAdvance,
    };
  }

  createDailyDetailsObject(
    element: IInvoicePayments,
    validatedPayments: any
  ): IDailyInvoicePaymentDetails {
    return {
      date: element.date,
      invoiceNumber: element.factura.billyInvoice![0].invoice_number,
      customerName: element.factura.cliente[0].nombre,
      invoiceValue: this.operationalReportingService.getInvoiceTotal(
        element.factura
      ),
      cashPayment: validatedPayments.cashPayment,
      cardPayment: validatedPayments.cardPayment,
      bankPayment: validatedPayments.bankPayment,
      creditPayment: 0,
      paymentCrossAdvance: validatedPayments.paymentCrossAdvance,
    };
  }

  calculateTaxedSale(invoice: any, sale: any): ITotalsByDepartamentDetails {
    const newInvoice: IQuota = invoice;
    const newSale: IVentaQuery = sale;
    let discountedSubtotal = 0;
    let totalsObject = {} as ITotalsByDepartamentDetails;
    if (newInvoice.tax_incl === "false") {
      totalsObject.fee = this.operationalReportingService.getTaxPercent(
        newSale,
        newInvoice
      );
      totalsObject.salesByFee = newSale.cantidad * newSale.precio;
      totalsObject.discountsOrFees =
        (newSale.descuento / 100) * totalsObject.salesByFee;
      discountedSubtotal =
        totalsObject.salesByFee - totalsObject.discountsOrFees;
      totalsObject.taxValue = discountedSubtotal * totalsObject.fee;
      totalsObject.netValue = discountedSubtotal + totalsObject.taxValue;
    } else if (newInvoice.tax_incl === "true") {
      totalsObject.fee = newSale.articulo[0].tax![0].value;
      totalsObject.salesByFee =
        newSale.cantidad * (newSale.precio / (totalsObject.fee + 1));
      totalsObject.discountsOrFees =
        (newSale.descuento / 100) * totalsObject.salesByFee;
      totalsObject.salesByFee =
        totalsObject.salesByFee - totalsObject.discountsOrFees;
      totalsObject.taxValue = totalsObject.salesByFee * totalsObject.fee;
      totalsObject.netValue = totalsObject.salesByFee + totalsObject.taxValue;
      totalsObject.consumptionTax = 0;
    }
    return {
      departamentCode: "700-" + newSale.articulo![0].grupoArt![0].id_grupo,
      departamentDescription: newSale.articulo![0].grupoArt![0].nombre,
      operation: this.operationalReportingService.validTaxedOperation(
        totalsObject.fee
      ),
      fee: totalsObject.fee,
      salesByFee: totalsObject.salesByFee,
      discountsOrFees: totalsObject.discountsOrFees,
      consumptionTax: totalsObject.consumptionTax,
      taxValue: totalsObject.taxValue,
      netValue: totalsObject.netValue,
    };
  }

  //Previus Deposits

  calculatePreviousDaysSaleDeposits(transfers: ITransfers[], cashierIds: number[]) {
    const filterTransfers = this.transferFiltersService.filterPreviusTransfers(transfers, cashierIds);
    let deposits = {} as IPreviousDaysSaleDeposits;
    const previusTransfersCalculated =
      this.calculatePreviusTransfers(filterTransfers);
    deposits = {
      deposits: previusTransfersCalculated.depositDetails,
      totalDeposits: previusTransfersCalculated.totalDeposits,
    };
    return deposits;
  }

  calculatePreviusTransfers(filterTransfers: ITransfers[]) {
    let depositDetails: IPreviousDaysSaleDepositDetails[] = [];
    let totalDeposits = 0;
    filterTransfers.forEach((element) => {
      const details = {
        date: element.date,
        value: element.custom_value,
        consignmentDescription:
          element.transferred.description + " " + element.id_correlative,
        consignmentNumber: element.id_correlative.toString()
      };
      depositDetails.push(details);
      totalDeposits += details.value;
    });
    return {
      depositDetails: depositDetails,
      totalDeposits: totalDeposits,
    };
  }

  //Other cash outflows

  calculateOtherCashOutflows(
    purchases: IPurchasePayments[],
    transfers: ITransfers[],
    advancePayments: ITreasury[],
    cashierIds: number[]
  ) {
    let otherCashOutflows: IOtherCashOutflows[] = [];
    const filteredData = this.getOtherCashOutflowsDataFiltered(
      purchases,
      transfers,
      advancePayments,
      cashierIds
    );
    otherCashOutflows = this.calculateOtherTransfers(
      filteredData.filterTransfers,
      otherCashOutflows,
      "EXIT TO ANOTHER CASHIER"
    );
    otherCashOutflows = this.calculateOtherPurchases(
      filteredData.filterPurchases,
      otherCashOutflows
    );
    otherCashOutflows = this.calculateOtherAdvancePayments(
      filteredData.filterAdvancePayments,
      otherCashOutflows,
      "ADVANCE PAYMENT (PROVIDER)"
    )
    return otherCashOutflows;
  }

  getOtherCashOutflowsDataFiltered(
    purchases: IPurchasePayments[],
    transfers: ITransfers[],
    advancePayments: ITreasury[],
    cashierIds: number[]
  ) {
    const filterTransfers = transfers.filter(
      (item) =>
        (item.original.type === "Cashier" && item.transferred.type === "Cashier") &&
        cashierIds.some(id => id === parseInt(item.original.id_payment.toString()))
    );
    const filterAdvancePayments = advancePayments.filter(
      (item) =>
        item.type === "Providers"
    )
    const filterPurchases = purchases.filter(
      (item) => item.paymentType[0].type === "Cashier"
    );
    return {
      filterTransfers: filterTransfers,
      filterPurchases: filterPurchases,
      filterAdvancePayments: filterAdvancePayments
    };
  }

  calculateOtherTransfers(
    filterTransfers: ITransfers[],
    otherCashOutflows: IOtherCashOutflows[],
    reason: string
  ) {
    let otherCashOutflowsObject = {} as IOtherCashOutflows;
    filterTransfers.forEach((element) => {
      otherCashOutflowsObject = {
        documentNumber: element.id_correlative,
        documentDescription: this.translate.instant(reason),
        date: element.date,
        value: element.custom_value,
      };
      otherCashOutflows.push(otherCashOutflowsObject);
    });
    return otherCashOutflows;
  }

  calculateOtherPurchases(
    filterPurchases: IPurchasePayments[],
    otherCashOutflows: IOtherCashOutflows[]
  ) {
    let otherCashOutflowsObject = {} as IOtherCashOutflows;
    filterPurchases.forEach((element) => {
      otherCashOutflowsObject = {
        documentNumber: element.id_correlative,
        documentDescription:
          this.translate.instant("PAYMENT PURCHASE") +
          ": " +
          element.purchaseInvoice.contpurchase![0].consecutive_invoice,
        date: element.date,
        value: element.value,
      };
      otherCashOutflows.push(otherCashOutflowsObject);
    });
    return otherCashOutflows;
  }

  calculateOtherAdvancePayments(
    filterAdvancePayments: ITreasury[],
    otherCashOutflows: IOtherCashOutflows[],
    reason: string
  ) {
    let otherCashOutflowsObject = {} as IOtherCashOutflows;
    filterAdvancePayments.forEach((element) => {
      otherCashOutflowsObject = {
        documentNumber: element.id_correlative,
        documentDescription:
          this.translate.instant(reason) +
          ": " +
          element.id_correlative,
        date: element.date,
        value: element.value - element.value_used,
      };
      otherCashOutflows.push(otherCashOutflowsObject);
    });
    return otherCashOutflows;
  }

  //Other cash inflows

  calculateOtherCashInflows(
    transfers: ITransfers[],
    advancePayments: ITreasury[],
    cashierIds: number[]
  ) {
    let otherCashInflows: IOtherCashOutflows[] = [];
    const filteredData = this.getOtherCashInflowsDataFiltered(
      transfers,
      advancePayments,
      cashierIds
    );
    otherCashInflows = this.calculateOtherTransfers(
      filteredData.filterTransfers,
      otherCashInflows,
      "ENTRY FOR ANOTHER CASHIER"
    );
    otherCashInflows = this.calculateOtherAdvancePayments(
      filteredData.filterAdvancePayments,
      otherCashInflows,
      "ADVANCE PAYMENT (CUSTOMER)"
    )
    return otherCashInflows;
  }

  getOtherCashInflowsDataFiltered(
    transfers: ITransfers[],
    advancePayments: ITreasury[],
    cashierIds: number[]
  ) {
    const filterTransfers = transfers.filter(
      (item) =>
        (item.transferred.type === "Cashier") &&
        cashierIds.some(id => id === parseInt(item.transferred.id_payment.toString()))
    );
    const filterAdvancePayments = advancePayments.filter(
      (item) =>
        item.type === "Customers"
    )
    return {
      filterTransfers: filterTransfers,
      filterAdvancePayments: filterAdvancePayments
    };
  }

  //Full details

  calculateCashReceivedDetails(
    invoceDetails: IDailyInvoicePayments,
    previousDaysSaleDeposits: IPreviousDaysSaleDeposits,
    cashPreviousDays: number,
    otherCashOutflows: IOtherCashOutflows[],
    otherCashInflows: IOtherCashOutflows[]
  ) {
    const cashPending =
      cashPreviousDays - previousDaysSaleDeposits.totalDeposits;
    const cashOfDay = invoceDetails.totalCashPayment;
    const cashOnHand = cashPending + cashOfDay;
    const totalCashOutflows = otherCashOutflows.reduce(
      (accumulator, currentValue) => accumulator + currentValue.value,
      0
    );
    const totalCashInflows = otherCashInflows.reduce(
      (accumulator, currentValue) => accumulator + currentValue.value,
      0
    );
    const cashReceivedDetails: ICashReceivedDetails = {
      cashPreviousDays: cashPreviousDays,
      cashPending: cashPending,
      cashOfDay: cashOfDay,
      cashOnHand: cashOnHand,
      otherCashOutflows: otherCashOutflows,
      otherCashInflows: otherCashInflows,
      totalCashOutflows: totalCashOutflows,
      totalCashInflows: totalCashInflows,
      totalToBeConsigned: (cashOnHand - totalCashOutflows) + totalCashInflows,
    };
    return cashReceivedDetails;
  }
}
