import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ICustomerSegment } from "src/app/@interfaces/customerSegment.interface";
import { CustomerService } from "src/app/@pages/customer/customer.service";
import { AlertsService } from "src/app/@shared/components/services/alerts/alerts.service";

@Component({
  selector: "app-crud-customer-segment",
  templateUrl: "./crud-customer-segment.component.html",
  styleUrls: ["./crud-customer-segment.component.css"],
})
export class CrudCustomerSegmentComponent implements OnInit, OnDestroy {
  registerForm: UntypedFormGroup = this.fb.group({
    segment: ["", [Validators.required]],
  });

  @Input() listSegments: Array<ICustomerSegment> = [];
  unsubscribe$ = new Subject();

  constructor(
    private fb: UntypedFormBuilder,
    private customer: CustomerService,
    private router: Router,
    private translate: TranslateService,
    private alertsService: AlertsService
  ) {}

  ngOnInit(): void {
    this.customer
      .listCustomerSegment()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        this.listSegments = result;
      });
  }

  remove(id: number, index: number) {
    this.alertsService
      .showConfirmAlert("You won not be able to revert this change!")
      .then((confirm) => {
        if (confirm.isConfirmed) {
          this.customer
            .removeCustomerSegment(id)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((result) => {
              if (!result) {
                Swal.fire(
                  "Error",
                  this.translate.instant("Something was wrong"),
                  "error"
                );
              } else {
                Swal.fire(
                  this.translate.instant("Good"),
                  this.translate.instant("Segment removed"),
                  "success"
                ).then((data) => {
                  if (data.isConfirmed) {
                    this.router
                      .navigateByUrl("/dialog", { skipLocationChange: true })
                      .then(() => {
                        this.router.navigate(["/customer"]);
                      });
                    this.customer
                      .listCustomerSegment()
                      .pipe(takeUntil(this.unsubscribe$))
                      .subscribe((result) => {
                        this.listSegments = result;
                      });
                  }
                });
              }
            });
        }
      });
  }

  update() {
    if (this.registerForm.invalid === true) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: this.translate.instant("Segment Invalid, Please Check!"),
      });
      return;
    }
    this.customer
      .createCustomerSegment(this.registerForm.get("segment")?.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          Swal.fire({
            title: this.translate.instant("Created"),
            text: this.translate.instant("Segment created successfully!"),
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#f44336",
            confirmButtonText: "Ok",
          }).then((data) => {
            if (data.isConfirmed) {
              this.router
                .navigateByUrl("/dialog", { skipLocationChange: true })
                .then(() => {
                  this.router.navigate(["/customer"]);
                });
              this.customer
                .listCustomerSegment()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((result) => {
                  this.listSegments = result;
                });
            }
          });
        } else {
          Swal.fire(
            this.translate.instant("Something was wrong"),
            this.translate.instant("Failed"),
            "error"
          );
        }
      });
  }

  textValid(text: string, index?: number) {
    return (
      this.registerForm.get(text)?.invalid &&
      this.registerForm.get(text)?.touched
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
