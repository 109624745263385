import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiToCalculateService } from '../../../../@core/api-to-calculate/api-to-calculate.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialReportsService {

  constructor(private httpClient: HttpClient, private apiToCalculateService: ApiToCalculateService) {
  }

  async getChartAccounts(startDate: string, endDate: string, companyId: string): Promise<Observable<any>> {
    try {
      await this.apiToCalculateService.setAuthApiProps();
      const headers = new HttpHeaders().set('Authorization', `Bearer ${this.apiToCalculateService.getToken}`).set('ngsw-bypass', 'true');
      return this.httpClient.post<any[]>(`${this.apiToCalculateService.getApiUrl}/accountingmovementsbydate/${startDate}/${endDate}/${companyId}`,
        this.apiToCalculateService.getApiBody, { headers }).
        pipe(map(res => res), catchError((err) => {
          throw of(err)
        }))
    } catch (error) {
      console.error("Error retreiving api auth", error)
      return of(error)
    }
  }
}
